import { AfterContentInit, Component, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { BaseMultiFieldComponent } from '../base-multi-field/base-multi-field.component';

@Component({
  selector: 'app-multi-select-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        [apply]="truncate"
        *ngIf="inline"
        aria-hidden="true"
      >
        {{ label }}</span
      >
      <mat-form-field
        [appearance]="appearance"
        floatLabel="always"
        [style.margin-top]="control?.value?.length > 0 ? null : '-0.3rem'"
      >
        <mat-select
          multiple
          [required]="required"
          [compareWith]="compareFn"
          [formControl]="control"
          [appIsReadOnly]="readOnly"
          (valueChange)="_valueChanged($event)"
        >
          <mat-select-trigger truncateText>{{ getDisplayValue(options, control.value) }}</mat-select-trigger>
          <mat-option truncateText *ngFor="let option of options" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <mat-hint>
          <div truncateText *ngIf="!control.parent?.disabled || !control.disabled">{{ hint }}</div>
        </mat-hint>
        <mat-error>
          <app-error-renderer [errors]="control.errors"></app-error-renderer>
        </mat-error>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class MultiSelectFieldComponent extends BaseMultiFieldComponent implements AfterContentInit {
  @ViewChild(MatSelect, { static: true }) select: MatSelect;

  constructor() {
    super();
  }

  // This forces select trigger to float
  ngAfterContentInit(): void {
    Object.defineProperty(this.select, 'empty', {
      get: function () {
        return false;
      }
    });
  }
}
