import { Component } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-text-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required, 'joined-field-label': isJoinedField }">
      <span
        class="label-text {{ isJoinedField ? 'other-label' : '' }} {{
          !control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''
        }}"
        truncateText
        [apply]="truncate"
        *ngIf="inline"
      >
        {{ isJoinedField ? ' ' : label }}
      </span>
      <mat-form-field [appearance]="appearance" floatLabel="always" [class]="{ 'other-label': isJoinedField }">
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label">
          {{ isJoinedField ? ' ' : label }}
        </mat-label>
        <input
          #input
          (input)="_valueChanged(input.value)"
          matInput
          [formControl]="control"
          type="text"
          [required]="required"
          [readonly]="readOnly"
        />
        <mat-hint>
          <div truncateText *ngIf="!control.parent?.disabled || !control.disabled">{{ hint }}</div>
        </mat-hint>
        <mat-error>
          <app-error-renderer [errors]="control.errors"></app-error-renderer>
        </mat-error>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class TextFieldComponent extends BaseFieldComponent {}
